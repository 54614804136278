import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { Context } from '../models/context';
import { Company } from '../models/domain';
import { AuthResponse, AuthService, ProfileInfo } from '../services/auth.service';
import { ContextService } from '../services/context.service';
import { DataService } from '../services/data.service';
import { Menu } from '../services/navigation.service';
import { SettingsService } from '../services/settings.service';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public keyword: string = "";
  public context: Context = {} as Context;
  public session: AuthResponse = {} as AuthResponse;
  public companies: Company[] = [];
  public employees: Map<number, string>;
  public isCmpVisible: boolean = true;
  public isEmpVisible: boolean = true;
  public shortcutMenus: Menu[] = [];
  public profileInfo: ProfileInfo;
  private ctxSubscription: Subscription;
  private handleMultiEvent: boolean = false;
  private menus: Menu[] = [];

  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private authService: AuthService,
    private settingsService: SettingsService,
    private contextService: ContextService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.context = this.authService.getContextData();
    if (this.context.persona === 'EMPLOYEE' || this.context.persona === 'HR' || this.context.persona === 'ACCOUNTANT') {
      this.settingsService.getEmployeeProfileInfo().subscribe({
        next: profileInfo => this.profileInfo = profileInfo
      });
    } else if (this.context.persona === 'ADMIN' || this.context.persona === 'CLIENT') {
      this.settingsService.getAdminProfileInfo().subscribe({
        next: profileInfo => this.profileInfo = profileInfo
      });
    }
    this.dataService.companies().subscribe(companies => this.companies = companies);
    this.settingsService.getCompanies(false).subscribe((companies: Company[]) => {
      this.companies = companies.filter(company => !company.disabled);
    });
    // this.dataService.employees().subscribe(employees => this.employees = employees);
    this.dataService.menus().subscribe(menus => {
      this.menus = menus.reduce((acc, m) => acc.concat(m.submenu), []);
      this.shortcutMenus = this.getShortcutMenus(this.context?.persona);
    });
    this.checkDropDownVisibility();
    this.initData(this.context.persona);
    this.reInitContext();
    this.loadScripts();
    this.router.events.pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!this.handleMultiEvent) {
        this.handleMultiEvent = true;
        this.resetContext(event.url);
        setTimeout(() => {
          this.handleMultiEvent = false;
        }, 1000);
      }
    });
  }

  private reInitContext() {
    this.ctxSubscription = this.dataService.onContextReInit().subscribe(inited => {
      if (inited) {
        this.initData(this.context.persona);
        this.dataService.reInitContext(false);
      }
    });
  }

  private initData(persona: string) {
    switch (persona) {
      case 'ADMIN':
        this.getCompanies();
        break;
      case 'CLIENT':
        //Statement
        break;
      default:
        this.getEmployees();
    }
  }
  public resetContext(currentUrl: string) {
    currentUrl = currentUrl.split('?')[0];
    const excludedUrls: string[] = ['/appearance/leave', '/appearance/absence-list', '/appearance/regular', '/appraisal/application-list', '/payroll/renumaration-list'];
    const roles: string[] = ['HR'];
    if (excludedUrls.includes(currentUrl) && roles.includes(this.authService.getPersona())) {
      this.callContext();
    }
  }

  private callContext() {
    this.authService.callContext().subscribe({
      next: context => {
        this.authService.setContext(context);
        this.context = this.authService.getContextData();
      }
    });
  }

  private getCompanies() {
    this.settingsService.getCompanies(false).subscribe((companies: Company[]) => {
      this.companies = companies.filter(company => !company.disabled);
    });
  }

  private getEmployees() {
    this.contextService.getEmployees().subscribe((employees: Map<number, string>) => this.employees = employees);
  }

  public parseId(id: any) {
    return parseInt(id);
  }

  public setContext() {
    this.context.companyName = this.companies?.find(c => c?.id === this.context.companyId)?.name ?? 'Robocodex';
    this.context.employeeName = this.employees[this.context?.employeeId];
    this.authService.setContext(this.context);
  }

  private loadScripts() {
    $(document).ready(function () {
      $('.mobile-search-icon').on('click', function () {
        $('.search-bar').addClass('full-search-bar');
      }),
        $('.search-close').on('click', function () {
          $('.search-bar').removeClass('full-search-bar');
        });
    });
  }

  private checkDropDownVisibility() {
    this.checkCompanyDropDownVisibility(this.router.url);
    this.checkEmployeeDropDownVisibility(this.router.url);
    this.router.events.pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event: any) => {
      this.checkCompanyDropDownVisibility(event.url);
      this.checkEmployeeDropDownVisibility(event.url);
    });
  }

  private checkCompanyDropDownVisibility(url: string) {
    const excludedUrls: string[] = ['/config/country-list', '/config/currency-list', '/config/timezone-list'];
    this.isCmpVisible = !excludedUrls.includes(url);
  }

  private checkEmployeeDropDownVisibility(url: string) {
    let excludedUrls: string[] = ['/setup/employee-list', '/setup/holiday-list', '/setup/title-list', '/setup/workday-list', '/appearance/absence-list', '/appearance/regular', '/appearance/leave', '/payroll/particular-list', "/payroll/providentFund-list", "/appraisal/appraisal-list", "/appraisal/application-list", "/appraisal/approval-list", "/appraisal/evaluation-list", "/setup/profile", "/appraisal/appraisal-cycle", "/appraisal/approval", "/appraisal/manage-approval", "/payroll/renumaration-list", "/appraisal/evaluation-report", "/finance/finance-report", "/finance/cashbook-list"];
    if (this.context?.persona === 'ACCOUNTANT') {
      excludedUrls = [...excludedUrls, "/setup/social-media",
        "/insight/dashboard",
        "/finance/account-list",
        "/finance/journal-list",
        "/finance/ledger-list",
        "/setup/family-list",
        "/payroll/bank-list"
      ];
    }
    if (this.context?.persona === 'EMPLOYEE') {
      excludedUrls = [...excludedUrls,
        "/payroll/bank-list",
      ];
    }
    this.isEmpVisible = !excludedUrls.includes(url?.split('?')[0]);
  }

  public toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  public getSuggestions = (text$: Observable<string>) => text$.pipe(
    switchMap((term) => term == '' ? of([]) : of(this.menus.filter((a, b) => a.title.toLowerCase().startsWith(term.toLowerCase())))
    ),
  );

  public search(result?: Menu) {
    this.router.navigate([result.path]);
    this.keyword = "";
  }

  public formatter1(r: any) {
    return '';
  }

  private getShortcutMenus(role: string): Menu[] {
    let menus: Menu[] = this.menus;
    switch (role) {
      case 'ADMIN':
        const adminTitles: string[] = ['Dashboard', 'Company'];
        menus = menus.filter((m: Menu) => adminTitles?.includes(m?.title));
        break;
      case 'CLIENT':
        const clientTitles: string[] = ['Dashboard', 'Role', 'Employee'];
        menus = menus.filter((m: Menu) => clientTitles?.includes(m?.title));
        break;
      case 'HR':
        const hrTitles: string[] = ['Dashboard', 'Employee', 'Leave', 'Absence', 'Regularization', 'Vacay', 'Salary', 'Approval', 'Evaluation'];
        menus = menus.filter((m: Menu) => hrTitles?.includes(m?.title));
        break;
    }
    return menus;
  }

  public logout() {
    this.authService.signout();
  }

  ngOnDestroy(): void {
    this.ctxSubscription?.unsubscribe();
    this.authService.logout();
  }
}
