import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "./sidebar.service";

import * as $ from 'jquery';
import { Menu, NavigationService } from '../services/navigation.service';
import { CompanyService } from '../services/company.service';
import { Info } from '../models/domain';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public info: Info = {} as Info;


  constructor(public sidebarservice: SidebarService,
    private dataService: DataService, private router: Router, private navServices: NavigationService, private companyService: CompanyService) {

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        this.dataService.menus().subscribe(menus => this.setPrivilege(menus));
      }

      if (event instanceof NavigationEnd && $(window).width() < 1025 && (document.readyState == 'complete' || false)) {

        this.toggleSidebar();
        // Hide loading indicator

      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

  }


  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($(".wrapper").hasClass("nav-collapsed")) {
      // unpin sidebar when hovered
      $(".wrapper").removeClass("nav-collapsed");
      $(".sidebar-wrapper").unbind("hover");
    } else {
      $(".wrapper").addClass("nav-collapsed");
      $(".sidebar-wrapper").hover(
        function () {
          $(".wrapper").addClass("sidebar-hovered");
        },
        function () {
          $(".wrapper").removeClass("sidebar-hovered");
        }
      )

    }

  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }


  ngOnInit() {
    this.navServices.getMenues().subscribe(menus => {
      this.menuItems = menus;
      let modules: string[] = [];
      menus.forEach(m => {
        modules.push(m.title.toUpperCase());
        m.submenu.forEach(s => {
          modules.push(s.title.toUpperCase());
        })
      })
      this.dataService.setSideMenus(modules);
      this.dataService.setMenus(menus);
      this.setPrivilege(menus);
    });
    this.companyService.getInfo().subscribe(info => this.info = info);
    $.getScript('./assets/js/app-sidebar.js');

  }

  private setPrivilege(menus: Menu[]) {
    const menuItems: Menu[] = menus.reduce((acc, m) => acc.concat(m.submenu), []);
    if (menuItems) {
      const menu: Menu = menuItems.find(m => m.path === this.router.url?.split('?')[0]);
      if (menu) this.dataService.setPrivilege(menu.privilege);
    }
  }

  public getTruncatedName(name: string, maxLength: number): string {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  }
}
