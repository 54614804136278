import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Address, Role, User } from '../models/context';
import { Company, Holiday, Message, Title, Workday } from '../models/domain';
import { ProfileInfo } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient) { }

  //HOLIDAY API'S
  public createHoliday(holiday: Holiday): Observable<Holiday> {
    return this.httpClient.post<Holiday>('/api/holiday/v1', holiday);
  }

  public getHolidays(company: number, date: string, disabled?: boolean): Observable<Holiday[]> {
    return this.httpClient.get<Holiday[]>('/api/holiday/v1', { params: { company, date, ...(disabled && { disabled }) } });

  }

  public getHoliday(id: number): Observable<Holiday> {
    return this.httpClient.get<Holiday>(`/api/holiday/v1/${id}`);
  }

  public updateHoliday(id: number, holiday: Holiday): Observable<Holiday> {
    return this.httpClient.put<Holiday>(`/api/holiday/v1/${id}`, holiday);
  }

  public deleteHoliday(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/holiday/v1/${id}`);
  }

  public toggleHolidayStatus(holiday: Holiday): Observable<Holiday> {
    return this.httpClient.put<Holiday>(`/api/holiday/v1/${holiday.id}`, holiday);
  }

  //DEFAULT API'S
  public getHolidayTypes(): string[] {
    return [
      'Public Holiday',
      'Local Holiday',
      'Religious Holiday',
      'Bank Holiday',
      'National Holiday',
      'Festival Holiday',
      'Government Holiday',
      'Cultural Holiday',
      'School Holiday',
      'Corporate Holiday'
    ];
  }

  public getWeekdays(): Observable<string[]> {
    return this.httpClient.get<string[]>('/api/context/v1/weekday');
  }

  //PROFILE INFO

  public getEmployeeProfileInfo(): Observable<ProfileInfo> {
    return this.httpClient.get<ProfileInfo>(`/api/profile/v1/employee-info`);
  }

  public getAdminProfileInfo(): Observable<ProfileInfo> {
    return this.httpClient.get<ProfileInfo>(`/api/profile/v1/company-info`);
  }

  //COMPANY API'S
  public createCompany(company: Company): Observable<Company> {
    return this.httpClient.post<Company>('/api/company/v1', company);
  }

  public getCompanies(disabled?: boolean): Observable<Company[]> {
    return this.httpClient.get<Company[]>('/api/company/v1', { params: { ...(disabled && { disabled }) } });
  }

  public getCompany(id: number): Observable<Company> {
    return this.httpClient.get<Company>(`/api/company/v1/${id}`);
  }

  public updateCompany(id: number, company: Company): Observable<Company> {
    return this.httpClient.put<Company>(`/api/company/v1/${id}`, company);
  }

  public deleteCompany(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/company/v1/${id}`);
  }

  public toggleCompanyStatus(company: Company): Observable<Company> {
    return this.httpClient.put<Company>(`/api/company/v1/${company.id}`, company);
  }

  public getPeriodTypes(): string[] {
    return [
      'FISCAL_YEAR',
      'CALENDAR_YEAR',
      'QUARTERLY',
      'MONTHLY',
      'WEEKLY'
    ];
  }

  //ROLE API'S
  public createRole(role: Role): Observable<Role> {
    return this.httpClient.post<Role>('/api/role/v1', role);
  }

  public getRoles(companyId: number, disabled?: boolean): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`/api/role/v1`, { params: { companyId, ...(disabled != null && { disabled }) } });
  }

  public getRole(id: number): Observable<Role> {
    return this.httpClient.get<Role>(`/api/role/v1/${id}`);
  }

  public updateRole(id: number, role: Role): Observable<Message> {
    return this.httpClient.put<Message>(`/api/role/v1/${id}`, role);
  }

  public deleteRole(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/role/v1/${id}`);
  }

  public toggleRoleStatus(role: Role): Observable<Role> {
    return this.httpClient.put<Role>(`/api/role/v1/${role.id}`, role);
  }

  public getPersonas() {
    return this.httpClient.get<string[]>('/api/role/v1/persona');
  }

  public getPrivileges() {
    return this.httpClient.get<string[]>('/api/role/v1/privilege');
  }

  public getDefaultModules(): Observable<Map<string, string>> {
    return this.httpClient.get<Map<string, string>>(`/api/menu/module`);
  }

  public getUserRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`/api/role/v1`);
  }

  //USER API'S
  public getUsers(company?: number): Observable<User[]> {
    return this.httpClient.get<User[]>('/api/employee/v1/user', { params: { ...company && { company } } });
  }

  public getUser(id: number): Observable<User> {
    return this.httpClient.get<User>(`/api/employee/v1/user/${id}`);
  }

  public createUser(user: User): Observable<Message> {
    return this.httpClient.post<Message>('/api/employee/v1/user', user);
  }

  public updateUser(id: number, user: User): Observable<Message> {
    return this.httpClient.put<Message>(`/api/employee/v1/user/${id}`, user);
  }

  public deleteUser(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/employee/v1/user/${id}`);
  }

  public createCompanyUser(companyId: number, user: User): Observable<Message> {
    return this.httpClient.post<Message>(`/api/employee/v1/user/admin/${companyId}`, user);
  }

  public getCompanyUser(companyId: number): Observable<User> {
    return this.httpClient.get<User>(`/api/employee/v1/user/admin/${companyId}`);
  }

  public toggleUserStatus(user: User): Observable<Message> {
    return this.httpClient.put<Message>(`/api/employee/v1/user/${user.id}`, user);
  }

  //ADDRESS API'S
  public getAddresses(employee: number, type: string): Observable<Address[]> {
    return this.httpClient.get<Address[]>(`/api/employee/v1/address?employee=${employee}&type=${type}`);
  }

  public getAddress(id: number): Observable<Address> {
    return this.httpClient.get<Address>(`/api/employee/v1/address/${id}`);
  }

  public createAddress(address: Address): Observable<Address> {
    return this.httpClient.post<Address>('/api/employee/v1/address', address);
  }

  public updateAddress(id: number, address: Address): Observable<Address> {
    return this.httpClient.put<Address>(`/api/employee/v1/address/${id}`, address);
  }

  public deleteAddress(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/employee/v1/address/${id}`);
  }

  //WORKDAY API'S
  public createWorkday(workday: Workday): Observable<Workday> {
    return this.httpClient.post<Workday>('/api/workday/v1', workday);
  }

  public getWorkday(id: number): Observable<Workday> {
    return this.httpClient.get<Workday>(`/api/workday/v1/${id}`);
  }

  public getWorkdays(company: number, disabled?: boolean): Observable<Workday[]> {
    return this.httpClient.get<Workday[]>('/api/workday/v1', { params: { company, ...(disabled && { disabled }) } });
  }

  public updateWorkday(id: number, workday: Workday): Observable<Workday> {
    return this.httpClient.put<Workday>(`/api/workday/v1/${id}`, workday);
  }

  public deleteWorkday(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/workday/v1/${id}`);
  }

  public toggleWorkdayStatus(workday: Workday): Observable<Workday> {
    return this.httpClient.put<Workday>(`/api/workday/v1/${workday.id}`, workday);
  }

  //TITLE API'S
  public createTitle(title: Title): Observable<Title> {
    return this.httpClient.post<Title>('/api/title/v1', title);
  }

  public getTitle(id: number): Observable<Title> {
    return this.httpClient.get<Title>(`/api/title/v1/${id}`);
  }

  public getTitles(company: number, band?: string, level?: number, disabled?: boolean): Observable<Title[]> {
    return this.httpClient.get<Title[]>('/api/title/v1', { params: { company, ...(band && { band }), ...(level && { level }), ...(disabled!=null && { disabled }) } });
  }

  public updateTitle(id: number, title: Title): Observable<Title> {
    return this.httpClient.put<Title>(`/api/title/v1/${id}`, title);
  }

  public deleteTitle(id: number): Observable<Message> {
    return this.httpClient.delete<Message>(`/api/title/v1/${id}`);
  }

  public toggleTitleStatus(title: Title): Observable<Title> {
    return this.httpClient.put<Title>(`/api/title/v1/${title.id}`, title);
  }
}
