import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { EmployeeResolver } from '../resolvers/employee.resolver';
import { ModuleResolver } from '../resolvers/module.resolver';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [

  {
    path: 'insight',
    loadChildren: () =>
      import('../../views/insight/insight.module').then((m) => m.InsightModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setup',
    loadChildren: () =>
      import('../../views/setup/setup.module').then((m) => m.SetupModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'appearance',
    loadChildren: () =>
      import('../../views/appearance/appearence.module').then((m) => m.AppearenceModule),
    resolve: { employeeId: EmployeeResolver},
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll',
    loadChildren: () =>
      import('../../views/payroll/payroll.module').then((m) => m.PayrollModule),
    resolve: { employeeId: EmployeeResolver},
    canActivate: [AuthGuard],
  },
  {
    path: 'resource',
    loadChildren: () =>
      import('../../views/resource/resource.module').then((m) => m.ResourceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'report',
    loadChildren: () =>
      import('../../views/report/report.module').then((m) => m.ReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'finance',
    loadChildren: () =>
      import('../../views/finance/finace.module').then((m) => m.FinaceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'faq',
    loadChildren: () => import('../../faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'appraisal',
    loadChildren: () =>
      import('../../views/appraisal/appraisal.module').then((m) => m.AppraisalModule),
    resolve: { employeeId: EmployeeResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'config',
    loadChildren: () =>
      import('../../views/config/config.module').then((m) => m.ConfigModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'company',
  //   loadChildren: () => import('../../company/company.module').then((m) => m.CompanyModule),
  // },
];
