import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor(private commonService: CommonService) { }

  private secretKey = 'r1o2b3o4c5o6d7e7x8'; // Use a strong secret key for encryption

  public encryptAndStore(array: any,key:string): void {
    const jsonString = JSON.stringify(array);
    const encrypted = CryptoJS.AES?.encrypt(jsonString, this.secretKey)?.toString();
    this.commonService?.setSessionItem(key, encrypted)
  }

  public decryptToArray(key:string): string[] {
    const encryptedData = this.commonService.getSessionItem(key)
    if (encryptedData) {
      const bytes = CryptoJS?.AES?.decrypt(encryptedData, this.secretKey);
      const decrypted = bytes?.toString(CryptoJS.enc.Utf8);
      return decrypted ? JSON?.parse(decrypted) as string[] :[];
    }
    return [];
  }

}
