export const Config = {
  key: 'rcx-session',
  menus: 'menus',
  // apiKey: 'e0c1-h2u3-r4c5-h6',
  rememberMe: 'rememberMe',
  context: 'context',
  menu_key: 'm-key'
}


export interface Country {
  id: number
  name: string
  code: string
  flag: string
  capital: string
  disabled: boolean
}

export interface Currency {
  id: number
  name: string
  symbol: string
  logo: string
  worth: number
  disabled: boolean
  countryId: number
  countryName: string
}

export interface Timezone {
  id: number
  name: string
  code: string
  label: string
  off: string
  daylight: boolean
  disabled: boolean
  countryId: number
  countryName: string
}
