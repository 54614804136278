import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Context } from '../models/context';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../models/config';
import { Message } from '../models/domain';

export interface AuthRequest {
  username: string;
  password: string;
  rememberMe?: boolean;
}
export interface AuthResponse {
  token: string;
  name: string;
  time: Date;
  context: string;
  companyId: number;
  uid: number;
  role: string;
  privileges: string[];
  failed?: boolean;
  message?: string;
}

export interface ProfileInfo{
  name: string
  avatar: string
  role: string
}

export interface ResetPassword{
  username: string;
  password: string;
  confirm: string;
}


export const SESSION_KEY = 'rcx-session';
export const CONTEXT = {
  PLATFORM: 'platform',
  COMPANY: 'company',
  EMPLOYEE: 'employee',
  CLIENT: 'client',
};
export const ROLE = {
  PLATFORM: 'Platform Admin',
  COMPANY: 'Company HR',
  EMPLOYEE: 'Company Employee',
  CLIENT: 'Client Executive',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private context = new BehaviorSubject<Context>(this.commonService.getSessionItem(Config.context) as Context);

  private isCompany: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private isEmployee: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService,
    private router: Router
  ) { }

  public setContext(context: Context) {
    let ctx:Context=JSON.parse(JSON.stringify(context));
    ctx.employeeId=parseInt(ctx.employeeId?.toString());
    this.commonService.setSessionItem(Config.context, ctx);
    this.context.next(context);
  }

  public getContext(): Observable<Context> {
    return this.context;
  }

  public getCompanyId(): number {
    return this.context.getValue()?.companyId ?? null;
  }

  public getEmployeeId(): number {
    return this.context.getValue()?.employeeId ?? null;
  }

  public getContextData(): Context {
    return this.context.getValue() ?? null;
  }

  public getPersona(): string {
    return this.context.getValue()?.persona ?? null;
  }










  public setCompany(status: boolean) {
    this.isCompany.next(status);
  }
  public getCompany() {
    return this.isCompany;
  }
  public setEmployee(status: boolean) {
    this.isEmployee.next(status);
  }
  public getEmployee() {
    return this.isEmployee;
  }


  public setSession(session: AuthResponse) {
    this.commonService.setSessionItem(SESSION_KEY, session);
  }
  public getSession(): AuthResponse {
    return this.commonService.getSessionItem(SESSION_KEY) as AuthResponse;
  }
  public getToken(): string | undefined {
    let session = this.getSession();
    return session ? session.token : undefined;
  }

  public getUserId(): number | undefined {
    let session = this.getSession();
    return session ? session.uid : undefined;
  }


  // public getCompanyId(): number | undefined {
  //   let session = this.getSession();
  //   return session ? session.companyId : undefined;
  // }

  public isAuthorized(): boolean {
    let session = this.getSession();
    return session && !!session.token && !!session.role;
  }
  public isStakeholderAuthorized(modules: string[]): boolean {
    let menus = this.commonService.getSessionItem(Config.menus);
    return this.isAuthorizedUser() && menus?.length > 0
      ? menus?.some((menu) => modules?.includes(menu))
      : false;
  }

  public isAuthorizedUser(): boolean {
    let user = this.commonService.getSessionItem(Config.key);
    let success, enhanced: boolean;
    user
      ? user.k?.charAt(0) === '1'
        ? (success = true)
        : (success = false)
      : undefined;
    return user && user.t && success;
  }
  public isAgent(agency: string) {
    let session = this.getSession();
    return (
      !session &&
      !session.context &&
      session.context.toUpperCase() == agency.toUpperCase()
    );
  }

  public logout() {
    this.commonService.removeSessionItem(Config.key);
    this.commonService.removeSessionItem(Config.menu_key);
    this.commonService.removeSessionItem(Config.context);
    this.router.navigate(['/auth/sign-in']);

  }

  public login(session: AuthResponse) {
    if (session) this.commonService.setSessionItem(SESSION_KEY, session);
  }

  public callContext(): Observable<Context> {
    return this.httpClient.get<Context>('/api/context/v1/context');
  }

  public signout(redirect?: string) {
    this.httpClient.delete<AuthResponse>('/api/auth/v1/signout').subscribe(
      (response) => {
        this.logout();
        this.commonService.showMessage(200, response.message);
      },
      (error) => {
        this.logout();
        this.commonService.showMessage(error.status, error.error);
      }
    );
  }

  public signin(request: AuthRequest, isLoggingIn: any) {
    this.httpClient.put<AuthResponse>('/api/auth/v1/signin', request).subscribe(
      (response) => {
        if (response && !response.failed) {
          this.login(response);
          if (request?.rememberMe) {
            request.password = this.commonService.encrypt(request.password);
            this.commonService.setLocalItem('rememberMe', request);
          }
          this.callContext().subscribe((context) => {
            if (context?.userId) {
              isLoggingIn.value = false;
              this.setContext(context);
              this.commonService.showMessage(
                200,
                response.message ? response.message : 'Welcome to Robocodex!'
              );
              this.router.navigate(['/insight/dashboard']);
            } else {
              isLoggingIn.value = false;
            }
          }, error => {
            isLoggingIn.value = false;
          });
        } else {
          isLoggingIn.value = false;
          this.commonService.showMessage(
            500,
            response.message ? response.message === 'User is bocked!' ? 'Login Failed! Please check your credentials or contact your administrator for access' : 'Invalid credentials. Please try again.' : 'Sorry, something went wrong!'
          );
        }
      },
      (error) => {
        isLoggingIn.value = false;
        this.commonService.showMessage(error.status, error.error);
      }
    );
  }

  //RESET PASSWORD API'S

  public sendPasscode(username:string): Observable<Message> {
    return this.httpClient.get<Message>(`/api/auth/v1/passcode/${username}`);
  }
  public validatePasscode(username: string, passcode: string): Observable<Message> {
    return this.httpClient.get<Message>(`/api/auth/v1/validate/passcode?username=${username}&passcode=${passcode}`);
  }
  public resetPassword(reset: ResetPassword): Observable<Message> {
    return this.httpClient.put<Message>(`api/auth/v1/reset`, reset);
  }
}
